/*
 * This file contains a bunch of ajax routes and requests
 * that at the time of writing still need function build around them.
 * https://gitlab.com/mijn_oefening/MijnOefening/issues/132
 */

/* global sendError */

/*
 * Gets all diets documents
 */
function getAllDiets() {
    $.get({
        url: "/ajax/therapist/diet",

        headers: {
            "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content")
        }
    });
}
window.getAllDiets = getAllDiets;

/*
 * Receives all advices (beweegadvies) documents
 */
function getAllAdvices() {
    $.get({
        url: "ajax/therapist/advice",

        headers: {
            "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content")
        }
    });
}
window.getAllAdvices = getAllAdvices;

/*
 * Receives all documents
 */
function getAllDocuments() {
    $.get({
        url: "/ajax/therapist/documents",

        headers: {
            "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content")
        }
    });
}
window.getAllDocuments = getAllDocuments;

/*
 * Receives all affliction (aandoeningen) documents
 */
function getAllAffliction() {
    $.get({
        url: "/ajax/therapist/affliction",

        headers: {
            "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content")
        }
    });
}
window.getAllAffliction = getAllAffliction;

/*
 * Receives all exercises, the public ones and the ones you have saved yourself.
 */
function getAllExercisesAjax(element) {
    $.ajax({
        method: "get",
        beforeSend() {
            element.html(crel("img", { class: "offset-md-6 mt-3", src: window.ajax_loader }));

            // console.log(element.html);
        },
        url: "/ajax/therapist/exercises",
        headers: {
            "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content")
        }
    })
        .done(data => {
            element.html(data);
        })
        .fail(data => {
            // console.log(data);
            sendError(data);
        })
        .always(() => {
            // alert( "finished" );
        });
}
window.getAllExercisesAjax = getAllExercisesAjax;

/*
 * Receives exercise lists by url
 */
function getExerciseList(url, callback) {
    $.get({
        url,
        headers: {
            "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content")
        }
    })
        .done(data => {
            callback(data);
        })
        .fail(data => {
            sendError(data);
        })
        .always(_ => {
            // alert( "finished" );
            // console.log(data)
        });
}

/*
 * Receives all exercise lists, the public ones and the ones you have saved yourself in the form of a view.
 */
function getAllExerciseList(element) {
    const url = "/ajax/therapist/exerciselists";
    getExerciseList(url, data => element.html(data));
}
window.getAllExerciseList = getAllExerciseList;

/*
 * Receives all custom exercise lists
 */
function getCustomExerciseList(element) {
    const url = "/ajax/therapist/exerciselists/custom";
    getExerciseList(url, data => element.html(data));
}
window.getCustomExerciseList = getCustomExerciseList;

/*
 * Receives all your patients.
 */
function getAllPatients() {
    $.get({
        url: "/ajax/therapist/patients",

        headers: {
            "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content")
        }
    });
}
window.getAllPatients = getAllPatients;

/*
 * Receives all the patients from your clinic.
 */
function patientsOfClinic() {
    $.get({
        url: "/ajax/therapist/patientsofclinic",

        headers: {
            "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content")
        }
    });
}
window.patientsOfClinic = patientsOfClinic;
